import {
  AuctionBiddingStatus,
  EAuctionBiddingStatus,
} from '@bonhams1793/bonhams-typescript'

export function shouldShowLiveUrl(
  allow_live_bid: boolean,
  lBidOnline: boolean,
  sBidOnlineStatus: EAuctionBiddingStatus,
  rowid: string
) {
  return (
    allow_live_bid &&
    lBidOnline &&
    sBidOnlineStatus === AuctionBiddingStatus.inProgress &&
    !!rowid
  )
}
