import { AuctionType } from '@bonhams1793/bonhams-typescript'
import styled from 'styled-components'

import { AMConfig } from '@nx/external/auction-mobility'
import { AuctionLot } from '@nx/global-types'

const LotCardAMWrapper = styled.div<{ $hasHammerPrice?: boolean }>`
  .am-data {
    > div:first-child {
      margin-top: 10px;
    }

    .am-bid-status-label {
      > div:first-child {
        padding-left: 0;
        padding-right: 0;
      }

      .current-label,
      .current-bid {
        width: auto;
        ${({ theme }) => theme.fontStyles.sourceSans.regular}
        color: ${({ theme }) => theme.colours.primary.black};
        font-weight: 600 !important;
        font-size: 16px !important;
      }

      .current-label::after {
        content: ':';
      }

      .win.winning {
        .current-label,
        .current-bid {
          color: ${({ theme }) => theme.colours.status.success} !important;
        }
      }

      .lost.outbid {
        .current-label,
        .current-bid {
          color: ${({ theme }) => theme.colours.status.error} !important;
        }
      }
    }

    .am-lot-status-label {
      width: auto;

      > div:first-child {
        ${({ theme }) => theme.fontStyles.sourceSans.regular}
        font-weight: 600 !important;
        font-size: 16px !important;
      }

      [class='sold'] {
        display: ${({ $hasHammerPrice }) =>
          $hasHammerPrice ? 'none' : 'block'};
      }

      .lost.outbid,
      .win.sold,
      .lost.sold {
        padding-left: 0;
      }

      .win.sold,
      .lost.sold {
        padding-bottom: 0;
      }

      .lost.sold {
        color: ${({ theme }) => theme.colours.grey['75']};
      }

      .lost.outbid {
        color: ${({ theme }) => theme.colours.status.error};
      }

      .win.sold {
        color: ${({ theme }) => theme.colours.status.success};
      }
    }

    .am-bid-box {
      width: fit-content;

      ${({ theme }) => theme.fontStyles.sourceSans.regular}
      > div:first-child {
        padding: 0;
        width: fit-content;
      }

      .bid-controls .bid-label.bid-price {
        font-size: 16px !important;
        font-weight: 600 !important;
        padding-top: 15px;

        &.win.winning {
          color: ${({ theme }) => theme.colours.status.success} !important;
        }

        &.lost {
          color: ${({ theme }) => theme.colours.status.error} !important;
        }
      }

      .bid-button-edit,
      .bid-button {
        color: ${({ theme }) => theme.colours.primary.black} !important;
        font-size: 16px !important;
        font-weight: 600 !important;
        width: fit-content;
        border: none !important;
        text-decoration: underline;
        text-transform: none !important;
        padding: 0;
        margin: 20px 0 0;
      }
    }

    .am-timer {
      min-height: 26px;
      display: flex;
      flex-flow: row nowrap;
      ${({ theme }) => theme.fontStyles.sourceSans.regular}
      font-size: 16px !important;
      color: ${({ theme }) => theme.colours.primary.charcoal} !important;

      & > .label {
        padding: 0 !important;
        margin: 0;
        height: auto;
        font-size: 16px !important;
        color: ${({ theme }) => theme.colours.primary.charcoal} !important;

        &::after {
          content: ': ';
          white-space: pre;
        }
      }

      .time-wrapper {
        display: flex;
        flex-flow: row nowrap;
        padding: 0;

        .label {
          display: none;
        }

        .digit {
          font-size: 16px !important;
          line-height: normal;
        }

        div {
          height: auto;
          width: auto;
          background: none;
          margin: 0;
        }

        .digit::after {
          content: ':';
        }

        div:last-child {
          & > .digit::after {
            content: '';
          }
        }
      }
    }
  }
`

const LotClosingInStringComponent = styled.div`
  color: ${({ theme }) => theme.colours.primary.charcoal} !important;
`

function LotCardAuctionMobility({
  lotAMId,
  auctionType,
  lotClosingInString,
  hasHammerPrice,
}: LotCardAuctionMobilityProps) {
  return (
    <LotCardAMWrapper $hasHammerPrice={hasHammerPrice}>
      <div
        id={`${AMConfig.wrapperPrefix}-${lotAMId}`}
        lot-id={lotAMId}
        className="am-data"
        data-testid="lot-card-am"
      >
        {auctionType === AuctionType.online && lotClosingInString ? (
          <LotClosingInStringComponent>
            {lotClosingInString}
          </LotClosingInStringComponent>
        ) : null}

        {auctionType === AuctionType.online && !lotClosingInString ? (
          <am-timer></am-timer>
        ) : null}

        <am-list-bid-status-label></am-list-bid-status-label>
        <am-list-lot-status-label></am-list-lot-status-label>
        <am-list-bid-box></am-list-bid-box>
      </div>
    </LotCardAMWrapper>
  )
}

export interface LotCardAuctionMobilityProps {
  lotAMId: AuctionLot['lotAMId']
  auctionType: AuctionLot['auctionType']
  lotClosingInString?: string
  hasHammerPrice?: boolean
}

export default LotCardAuctionMobility
