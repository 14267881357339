import styled, { css } from 'styled-components'

import { FireStyledIconButton } from '@nx/fire/follow-button'
import { FireImage } from '@nx/fire/image'
import { FireLink } from '@nx/fire/link'

export const StyledLotCardImageContainer = styled.div<{
  $hasImage: boolean
  $isFollowing: boolean
  $size: 'S' | 'M'
}>`
  ${({
    theme: { media, helpers, zIndex },
    $hasImage,
    $isFollowing,
    $size,
  }) => css`
    ${helpers.noLinkUnderline}

    display: block;
    font-size: 0;
    width: ${$size === 'S' ? '50px' : '100px'};
    position: relative;

    ${media.up.md} {
      align-items: ${!$hasImage ? 'center' : 'end'};
      align-self: ${$size === 'S' ? 'start' : 'end'};
      display: flex;
      justify-content: flex-start;
      padding: 0;
      width: ${$size === 'S' ? '50px' : '100%'};

      &:hover ${FireStyledIconButton} {
        visibility: visible;
      }
    }

    ${FireStyledIconButton} {
      ${!$isFollowing &&
      css`
        visibility: hidden;
      `}
      position: absolute;
      z-index: ${zIndex.lotCardLike};
      bottom: 10px;
      right: 10px;
    }
  `}
`

export const StyledLotCardImageLink = styled(FireLink)`
  display: block;
  width: 100%;
`

export const StyledLotCardImage = styled(FireImage)<{
  $hasImage: boolean
  $size: 'S' | 'M'
}>`
  ${({ theme: { media, colours }, $hasImage, $size }) => css`
    background-color: ${!$hasImage ? colours.primary.white : null};
    height: unset !important;
    max-height: ${$size === 'S' ? '50px !important' : '500px !important'};
    object-fit: contain;
    object-position: left;
    padding: ${!$hasImage ? '17px 5% !important' : null};
    position: relative !important;
    width: 100% !important;

    ${media.up.md} {
      padding: ${!$hasImage ? '65px 5% !important' : 0};
    }
  `}
`
