import {
  AuctionBiddingEngine,
  EAuctionBiddingEngine,
} from '@bonhams1793/bonhams-typescript'

import { decodeHtml } from '@nx/decode-html'
import { LotSymbol as LotSymbolAlgolia } from '@nx/global-types'
import { SmartlingAttributes } from '@nx/smartling'
import { getTranslate } from '@nx/translations'

import {
  LotCardTitleContainer,
  LotSymbol,
  StyledLotCardTitleContainer,
  StyledLotCardTitleHeading,
  StyledLotCardTitleStatus,
} from './LotCardTitle.styles'
import translate from './LotCardTitle.translate.json'

const { text } = getTranslate(translate)

function LotCardTitle({
  lotId,
  styledDescription,
  title,
  symbols,
  heading,
  isShowingLotId,
  biddingEngine,
  isExhibition,
  isPreview,
  ...props
}: LotCardTitleProps) {
  const comingSoon = isPreview && !isExhibition
  const symbol = symbols.map(({ symbol }) => decodeHtml(symbol)).join()

  return (
    <LotCardTitleContainer {...props}>
      {heading && (
        <StyledLotCardTitleHeading
          as="span"
          $level="XS"
          data-testid="lot-card-title-heading"
          {...SmartlingAttributes.noTranslate}
        >
          {heading}
        </StyledLotCardTitleHeading>
      )}
      {(biddingEngine === AuctionBiddingEngine.BRK ||
        biddingEngine === AuctionBiddingEngine.BUK) && (
        <StyledLotCardTitleHeading as="span" $level="XS" $isDivider={!!heading}>
          {text('lotCardTitle.offered', {
            brand:
              biddingEngine === AuctionBiddingEngine.BRK
                ? 'Bruun Rasmussen'
                : 'Bukowskis',
          })}
        </StyledLotCardTitleHeading>
      )}
      {comingSoon && (
        <StyledLotCardTitleStatus $level="XS">
          {text('lotCardTitle.soon')}
        </StyledLotCardTitleStatus>
      )}
      <StyledLotCardTitleContainer
        as="h3"
        $level={6}
        {...SmartlingAttributes.noTranslate}
      >
        {!comingSoon && isShowingLotId && (
          <LotSymbol
            title={symbols
              .map(({ longDescription }) => decodeHtml(longDescription))
              .join(' ')}
          >
            {lotId}
            <sup>{symbol}</sup> —{' '}
          </LotSymbol>
        )}
        <span
          dangerouslySetInnerHTML={{
            __html: styledDescription ? styledDescription : title,
          }}
        />
      </StyledLotCardTitleContainer>
    </LotCardTitleContainer>
  )
}

export interface LotCardTitleProps {
  lotId: string
  styledDescription?: string
  title: string
  symbols: LotSymbolAlgolia[]
  heading: string
  isShowingLotId: boolean
  biddingEngine: EAuctionBiddingEngine
  isExhibition: boolean
  isPreview: boolean
}

export default LotCardTitle
