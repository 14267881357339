import { LegacyImageValue, LotStatus } from '@bonhams1793/bonhams-typescript'

import { LotResponse } from '@nx/api'
import { AuctionLot } from '@nx/global-types'

import { LotCardItemProps } from '@web/components/common/LotCard/LotCard.types'

export function mapLotResponseToLotCardItem(
  lot: LotResponse
): LotCardItemProps {
  let mainImageUsePosition = -1
  const mainImage = lot.images.find((image) => {
    mainImageUsePosition = image.imageuses.findIndex(
      (imageUse) => imageUse.sImageUse === 'MAIN'
    )
    return mainImageUsePosition > -1 && image
  })
  const lotImage: LegacyImageValue = {
    url: mainImage?.image_url,
    imageURLParams: mainImage?.imageuses[mainImageUsePosition].sURLParams,
    caption: lot.sDesc,
  }

  return {
    image: lotImage,
    lotItemId: lot.iSaleItemNo,
    lotIdNumber: lot.iSaleLotNo,
    lotIdLetter: lot.sSaleLotNoA,
    lotId: `${lot.iSaleLotNo}${lot.sSaleLotNoA}`,
    lotUniqueId: lot.iSaleLotNoUnique,
    biddingEngine: 'AM',
    symbols: lot.lot_symbols.map((symbol) => ({
      symbol: symbol.sLotSymbol,
      shortDescription: symbol.sShortDesc,
      longDescription: symbol.sLongDesc,
    })),
    title: lot.sDesc,
    styledDescription: lot.sStyledDesc,
    heading: lot.sExtraDesc,
    status: lot.sLotStatus,
    isToBeSold: lot.sLotStatus === LotStatus.new,
    isPreview: lot.lPreviewLot,
    isResultPublished: !lot.lNotPublishedOnWeb,
    isExhibition: lot.lExhibition,
    isReferToDepartment: lot.lReferDept,
    isWithoutReserve: lot.lWithoutReserve,
    isAmended: Boolean(lot.lAddendum),
    currencySymbol: lot.sCurrencySymbol,
    currencyISO: lot.currency?.iso_code,
    hammerPrice: lot.dHammerPrice,
    hammerPremium: lot.dHammerPremium,
    estimateLow: lot.dEstimateLow,
    estimateHigh: lot.dEstimateHigh,
    GBPLowEstimate: lot.dLotLowValue,
    GBPHighEstimate: lot.dLotHighValue,
    auctionType: lot.iSaleNo.sSaleType,
    lotAMId: lot.rowid || '',
    auctionId: lot.iSaleNo.iSaleNo,
    brand: lot.brand,
    hammerTime: lot.hammerTime,
    biddableFrom: lot.biddableFrom || null,
    url: '',
  }
}

export function mapAlgoliaLotToLotCardItem(lot: AuctionLot): LotCardItemProps {
  return {
    image: lot.image || null,
    lotItemId: lot.lotItemId,
    lotIdNumber: lot.lotNo.number,
    lotIdLetter: lot.lotNo.letter,
    lotId: lot.lotId,
    lotUniqueId: lot.lotUniqueId,
    biddingEngine: lot.biddingEngine,
    symbols: lot.symbols,
    title: lot.title,
    styledDescription: lot.styledDescription,
    heading: lot.heading,
    status: lot.status,
    auctionStatus: lot.auctionStatus,
    biddingStatus: lot.auctionBiddingStatus,
    isToBeSold: lot.flags.isToBeSold,
    isPreview: lot.flags.isPreview,
    isResultPublished: lot.flags.isResultPublished,
    isExhibition: lot.flags.isExhibition,
    isReferToDepartment: lot.flags.isReferToDepartment,
    isWithoutReserve: lot.flags.isWithoutReserve,
    isAmended: lot.flags.isAmended,
    currencySymbol: lot.price.currencySymbol,
    currencyISO: lot.currency?.iso_code,
    hammerPrice: lot.price.hammerPrice,
    hammerPremium: lot.price.hammerPremium,
    estimateLow: lot.price.estimateLow,
    estimateHigh: lot.price.estimateHigh,
    GBPLowEstimate: lot.price.GBPLowEstimate,
    GBPHighEstimate: lot.price.GBPHighEstimate,
    auctionType: lot.auctionType,
    lotAMId: lot.lotAMId,
    auctionId: lot.auctionId,
    brand: lot.brand,
    url: lot.url ?? '',
    hammerTime: {
      datetime: lot.hammerTime.datetime,
      timezone: lot.hammerTime.timezone,
    },
    biddableFrom: lot.biddableFrom
      ? {
          datetime: lot.biddableFrom.datetime,
          timezone: lot.biddableFrom.timezone,
        }
      : null,
  }
}
